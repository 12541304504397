import React, { useMemo, useState } from 'react';
import { HiOutlineTrash } from 'react-icons/hi';
import { TbEdit } from 'react-icons/tb';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import CreateInventoryModal from '../inventory/components/CreateInventoryModal';
import ProcurementModal from './ProcuremenrModal';

interface ProcurementTabsProps {
  name?: string;
  isActive?: boolean;
  onClick: () => void | Promise<void>;
}

const ProcurementTab = ({ name, isActive, onClick }: ProcurementTabsProps) => {
  const [showModal, setShowModal] = useState(false);
  let { data } = useAppSelector((m) => m.procurement);
  let { procurementId } = useParams();
  const [editing, setEditing] = useState(false);

  let current = useMemo(() => {
    return data.find((m) => m._id === procurementId);
  }, [procurementId, data]);

  return (
    <>
      {editing && <ProcurementModal isEditing value={current} closer={() => setEditing(false)} />}
      <div
        onClick={() => {
          onClick();
        }}
        className={`flex items-center flex-1 max-w-max ${
          isActive ? ' min-w-max' : ''
        } overflow-hidden gap-2 px-3 py-1 cursor-pointer border border-t-bbg rounded-t-lg ${
          isActive ? 'active bg-white border-b-white' : ' bg-blueShades-5 border-x-bbg'
        } `}>
        <div
          className="flex items-center justify-start gap-2"
          onClick={(e) => {
            //   e.stopPropagation();
            //   m.selectInventory(m);
          }}>
          <span
            className={`max-w-[9em] whitespace-nowrap inline-block  overflow-x-auto ${
              isActive ? 'text-bblue' : 'text-bblack-1'
            }`}>
            {name}
          </span>
        </div>
        {isActive && (
          <div className="flex items-center gap-2">
            <span
              onClick={(e) => {
                e.stopPropagation();
                setEditing(true);
              }}
              className=" px-2 rounded-full hover:bg-ashShade-0">
              <TbEdit />
            </span>
            <span>
              <HiOutlineTrash onClick={() => {}} className="text-redShade-0" />
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default ProcurementTab;
